import React from 'react'
import Hero from '../Hero'
import SupportIcon from './SupportIcon'

const HeroSupport = ({
    heroTextTitle,
    heroTextSmallText,
    ctaTitle,
    heroData
}) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <SupportIcon />
        </Hero>
    )
}

export default HeroSupport
