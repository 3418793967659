import React from "react"
import TiltAnimationWrapper from "../../TiltAnimation/TiltAnimation"
const TextImageJamStack = ({ title, description, children }) => {
    return (
        <section className="py-12 md:py-[156px] ">
            <div className="container">
                <div className="grid grid-cols-1 items-center justify-items-center gap-y-10 text-center md:grid-cols-2 md:text-left lg:gap-x-[106px]">
                    <div className="place-self-center">
                        <h2 className="mx-auto mb-7 text-4xl font-bold leading-9 text-black md:text-5xl md:leading-[68px]">
                            {title}
                        </h2>
                        <p className="mb-3 leading-6 tracking-[-0.025rem] text-[#1D2333]/[0.8] md:mb-8 md:text-sm md:leading-7 lg:mb-[38px] lg:text-lg lg:leading-9">
                            {description}
                        </p>
                    </div>

                    <div className="max-w-xs overflow-hidden md:max-w-[464px]">
                        <TiltAnimationWrapper>{children}</TiltAnimationWrapper>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TextImageJamStack
