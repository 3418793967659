import React from 'react'
import { useSpring, animated } from 'react-spring'

const rotateScale = (x, y) => {
    const rotateY = -(y - window.innerHeight / 2) / 200
    const rotateX = (x - window.innerWidth / 2) / 200
    const scale = 1.03
    return [rotateY, rotateX, scale]
}
const transform = (x, y, s) =>
    `perspective(500px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function TiltAnimationWrapper({ children }) {
    const [props, api] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 450, friction: 40 }
    }))
    return (
        <animated.div
            onMouseMove={({ clientX: x, clientY: y }) =>
                api.start({ xys: rotateScale(x, y) })
            }
            onMouseLeave={() => api.start({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.to(transform) }}
        >
            {children}
        </animated.div>
    )
}

export default TiltAnimationWrapper
